<template>
  <div class="con-wrap">
    <VacationAddPopup
      :idx="pop.idx"
      v-if="pop.isShowAdd"
      @close="pop.hideAddVacation"
    ></VacationAddPopup>
    <VacationUsePopup
      :idx="pop.idx"
      v-if="pop.isShowUse"
      @close="pop.hideUseVacation"
    ></VacationUsePopup>

    <CarrotTitle
      :title="view.info.ename + '(' + view.info.kname + ') 휴가관리'"
    >
      - 입사일 기준 만 1년 시 근속년수에 해당하는 연차가 부여됩니다.<br />
      - 연차는 계획적으로 사용하며, 월 1회 사용을 원칙으로 합니다.
      <button
        v-if="view.state.pd_auth"
        type="button"
        @click="pop.showUseVacation"
        class="btn-default float-right ml-10"
      >
        사용휴가 등록
      </button>
      <button
        v-if="view.state.pd_auth"
        type="button"
        @click="pop.showAddVacation"
        class="btn-default float-right"
      >
        추가휴가 등록
      </button>
      <div class="clear"></div>
    </CarrotTitle>
    <div class="board">
      <div class="mt-40">
        <div>
          <table class="table-col w-600px">
            <colgroup>
              <col span="4" />
            </colgroup>
            <thead>
              <tr>
                <th>입사일</th>
                <th>부문</th>
                <th>본부</th>
                <th>팀</th>
                <th>Today</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{{ view.info.joindate }}</td>
                <td>{{ view.info.sector_name }}</td>
                <td>{{ view.info.office_name }}</td>
                <td>{{ view.info.team_name }}</td>
                <td>{{ view.info.state_txt }}</td>
              </tr>
            </tbody>
          </table>

          <div class="block-title mt-50 mb-20">연차현황</div>
          <table class="table-col table-auto">
            <!-- <colgroup>
              <col span="15" />
            </colgroup> -->
            <thead>
              <tr>
                <th colspan="3">루키</th>
                <th colspan="3">올해연차</th>
                <th colspan="3">아웃팅</th>
                <th colspan="3">전년도이월</th>
                <th colspan="3">그 외</th>
                <th colspan="3">합계</th>
              </tr>
              <tr>
                <th>총지급</th>
                <th>사용</th>
                <th>잔여</th>
                <th>총지급</th>
                <th>사용</th>
                <th>잔여</th>
                <th>총지급</th>
                <th>사용</th>
                <th>잔여</th>
                <th>이월연차</th>
                <th>사용</th>
                <th>잔여</th>
                <th>총지급</th>
                <th>사용</th>
                <th>잔여</th>
                <th>총지급</th>
                <th>사용</th>
                <th>잔여</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <span :class="{ 'color-1': view.vacation.rookie.tot < 0 }">{{
                    view.vacation.rookie.tot
                  }}</span>
                </td>
                <td>
                  <span :class="{ 'color-1': view.vacation.rookie.use < 0 }">{{
                    view.vacation.rookie.use
                  }}</span>
                </td>
                <td>
                  <span :class="{ 'color-1': view.vacation.rookie.cur < 0 }">{{
                    view.vacation.rookie.cur
                  }}</span>
                </td>
                <td>
                  <span :class="{ 'color-1': view.vacation.annual.tot < 0 }">{{
                    view.vacation.annual.tot
                  }}</span>
                </td>
                <td>
                  <span :class="{ 'color-1': view.vacation.annual.use < 0 }">{{
                    view.vacation.annual.use
                  }}</span>
                </td>
                <td>
                  <span :class="{ 'color-1': view.vacation.annual.cur < 0 }">{{
                    view.vacation.annual.cur
                  }}</span>
                </td>
                <td>
                  <span :class="{ 'color-1': view.vacation.outing.tot < 0 }">{{
                    view.vacation.outing.tot
                  }}</span>
                </td>
                <td>
                  <span :class="{ 'color-1': view.vacation.outing.use < 0 }">{{
                    view.vacation.outing.use
                  }}</span>
                </td>
                <td>
                  <span :class="{ 'color-1': view.vacation.outing.cur < 0 }">{{
                    view.vacation.outing.cur
                  }}</span>
                </td>
                <td>
                  <span
                    :class="{ 'color-1': view.vacation.carryover.tot < 0 }"
                    >{{ view.vacation.carryover.tot }}</span
                  >
                </td>
                <td>
                  <span
                    :class="{ 'color-1': view.vacation.carryover.use < 0 }"
                    >{{ view.vacation.carryover.use }}</span
                  >
                </td>
                <td>
                  <span
                    :class="{ 'color-1': view.vacation.carryover.cur < 0 }"
                    >{{ view.vacation.carryover.cur }}</span
                  >
                </td>
                <td>
                  <span :class="{ 'color-1': view.vacation.etc.tot < 0 }">{{
                    view.vacation.etc.tot
                  }}</span>
                </td>
                <td>
                  <span :class="{ 'color-1': view.vacation.etc.use < 0 }">{{
                    view.vacation.etc.use
                  }}</span>
                </td>
                <td>
                  <span :class="{ 'color-1': view.vacation.etc.cur < 0 }">{{
                    view.vacation.etc.cur
                  }}</span>
                </td>
                <td>
                  <span
                    :class="{ 'color-1': view.vacation.tot_vacation < 0 }"
                    >{{ view.vacation.tot_vacation }}</span
                  >
                </td>
                <td>
                  <span
                    :class="{ 'color-1': view.vacation.use_vacation < 0 }"
                    >{{ view.vacation.use_vacation }}</span
                  >
                </td>
                <td>
                  <span
                    :class="{ 'color-1': view.vacation.cur_vacation < 0 }"
                    >{{ view.vacation.cur_vacation }}</span
                  >
                </td>
              </tr>
            </tbody>
          </table>

          <div class="block-title mt-50 mb-20">사용연차</div>
          <table class="table-col table-auto">
            <!-- <colgroup>
              <col span="14" />
            </colgroup> -->
            <thead>
              <tr>
                <th rowspan="2">루키</th>
                <th rowspan="2">올해연차</th>
                <th rowspan="2">아웃팅</th>
                <th rowspan="2">전년도<br />이월</th>
                <th colspan="11">대체 휴가</th>
                <th rowspan="2">합계</th>
              </tr>
              <tr>
                <th>경조휴가</th>
                <th>산전후<br />휴가</th>
                <th>배우자<br />출산</th>
                <!-- <th>외근</th> -->
                <th>출장</th>
                <th>교육</th>
                <th>워크샵</th>
                <th>병역훈련</th>
                <th>백신휴가</th>
                <th>태아검진휴가</th>
                <!-- <th>병가</th>
                                <th style="border-right: 1px solid #ddd;">무급휴가</th> -->
                <th>휴일근무</th>
                <th style="border-right: 1px solid #ddd">기타</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <span :class="{ 'color-1': view.used.rookie < 0 }">{{
                    view.used.rookie
                  }}</span>
                </td>
                <td>
                  <span :class="{ 'color-1': view.used.annual < 0 }">{{
                    view.used.annual
                  }}</span>
                </td>
                <td>
                  <span :class="{ 'color-1': view.used.outing < 0 }">{{
                    view.used.outing
                  }}</span>
                </td>
                <td>
                  <span :class="{ 'color-1': view.used.carryover < 0 }">{{
                    view.used.carryover
                  }}</span>
                </td>

                <td>
                  <span :class="{ 'color-1': view.used.compassionate < 0 }">{{
                    view.used.compassionate
                  }}</span>
                </td>
                <td>
                  <span :class="{ 'color-1': view.used.maternity < 0 }">{{
                    view.used.maternity
                  }}</span>
                </td>
                <td>
                  <span :class="{ 'color-1': view.used.maternity2 < 0 }">{{
                    view.used.maternity2
                  }}</span>
                </td>
                <!-- <td><span :class="{ 'color-1':view.used.outside<0 }">{{ view.used.outside }}</span></td> -->
                <td>
                  <span :class="{ 'color-1': view.used.biztrip < 0 }">{{
                    view.used.biztrip
                  }}</span>
                </td>
                <td>
                  <span :class="{ 'color-1': view.used.education < 0 }">{{
                    view.used.education
                  }}</span>
                </td>
                <td>
                  <span :class="{ 'color-1': view.used.workshop < 0 }">{{
                    view.used.workshop
                  }}</span>
                </td>
                <td>
                  <span :class="{ 'color-1': view.used.reservedmili < 0 }">{{
                    view.used.reservedmili
                  }}</span>
                </td>
                <td>
                  <span :class="{ 'color-1': view.used.medicheck < 0 }">{{
                    view.used.medicheck
                  }}</span>
                </td>
                <td>
                  <span
                    :class="{ 'color-1': view.used.prenatal_diagnosis < 0 }"
                    >{{ view.used.prenatal_diagnosis }}</span
                  >
                </td>
                <!-- <td><span :class="{ 'color-1':view.used.sick<0 }">{{ view.used.sick }}</span></td>
                                <td><span :class="{ 'color-1':view.used.unpaid<0 }">{{ view.used.unpaid }}</span></td> -->
                <td>
                  <span :class="{ 'color-1': view.used.comptime < 0 }">{{
                    view.used.comptime
                  }}</span>
                </td>
                <td>
                  <span :class="{ 'color-1': view.used.alternative_etc < 0 }">{{
                      view.used.alternative_etc
                    }}</span>
                </td>
                <td>
                  <span :class="{ 'color-1': view.used.total < 0 }">{{
                    view.used.total
                  }}</span>
                </td>
              </tr>
            </tbody>
          </table>

          <div class="tabDefult-col dp-table w-100per mt-50 mb-20">
            <div class="float-left">
              <button
                class="tab-default"
                :class="{ on: view.type == 'detail' }"
                @click="view.viewVaTab('detail')"
              >
                상세휴가일정
              </button>
              <button
                class="tab-default"
                :class="{ on: view.type == 'plus' }"
                @click="view.viewVaTab('plus')"
              >
                추가휴가
              </button>
              <button
                class="tab-default"
                :class="{ on: view.type == 'minus' }"
                @click="view.viewVaTab('minus')"
              >
                차감휴가
              </button>
            </div>
            <div class="float-right">
              <select
                class="w-150px"
                v-model="view.year"
                @change="view.changeYear()"
              >
                <option :value="v" v-for="(v, k) in view.search_years" :key="k">
                  {{ v }}
                </option>
              </select>
            </div>
          </div>
          <table class="table-col">
            <colgroup>
              <col width="80" />
              <col width="*" />
              <col width="100" />
              <col width="100" />
              <col width="100" />
              <col width="100" />
              <col width="200" />
              <col v-if="view.state.pd_auth" width="100" />
            </colgroup>
            <thead>
              <tr>
                <th>No</th>
                <th>휴가종류</th>
                <th>구분</th>
                <th>휴가시작</th>
                <th>휴가종료</th>
                <th>휴가일수</th>
                <th>메모</th>
                <th v-if="view.state.pd_auth">-</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(irow, i) in view.list" :key="i">
                <td>{{ irow.num }}</td>
                <td>{{ irow.vtype_txt }}</td>
                <td>{{ irow.utype_txt }}</td>
                <td>{{ irow.sdate }}</td>
                <td>{{ irow.edate }}</td>
                <td>{{ irow.days }}</td>
                <td>{{ irow.memo }}</td>
                <td v-if="view.state.pd_auth">
                  <button
                    type="button"
                    @click="view.doDelete(irow.idx)"
                    class="btn-default float-right ml-10"
                  >
                    삭제
                  </button>
                </td>
              </tr>
              <tr v-if="view.total == 0">
                <td v-if="view.state.pd_auth" colspan="7">
                  휴가 내역이 없습니다.
                </td>
                <td v-else colspan="6">휴가 내역이 없습니다.</td>
              </tr>
            </tbody>
          </table>
          <CarrotPaging
            :total="view.total"
            :list_per_page="view.rows"
            v-model="view.page"
            @change="view.doSearch"
          ></CarrotPaging>
        </div>
        <div>
          <button class="btn-default float-left mt-30" @click="view.goList">
            목록
          </button>
          <div class="clear"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { onMounted, reactive } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useToast } from "vue-toastification";
import axios from "@/plugins/axios.js";
import Swal from "sweetalert2";
import VacationAddPopup from "@/components/popup/myPIMS/VacationAddPopup.vue";
import VacationUsePopup from "@/components/popup/myPIMS/VacationUsePopup.vue";
import { useStore } from "vuex";

export default {
  layout: "myPIMS",
  components: {
    VacationAddPopup,
    VacationUsePopup,
  },
  setup() {
    const router = new useRouter();
    const route = new useRoute();
    const toast = useToast();
    const store = useStore();

    const pop = reactive({
      // Popup 노출 여부
      isShowAdd: false,
      isShowUse: false,
      idx: 0,

      showAddVacation: () => {
        if (view.isAuth == false) {
          Swal.fire({
            title: "휴가관리",
            text: "접근 권한이 없습니다.",
          });
          return;
        }
        pop.idx = view.idx;
        pop.isShowAdd = true;
      },

      hideAddVacation: () => {
        view.doSearchVA();
        pop.isShowAdd = false;
      },

      showUseVacation: () => {
        if (view.isAuth == false) {
          Swal.fire({
            title: "휴가관리",
            text: "접근 권한이 없습니다.",
          });
          return;
        }
        pop.idx = view.idx;
        pop.isShowUse = true;
      },

      hideUseVacation: () => {
        view.doSearchVA();
        pop.isShowUse = false;
      },
    });

    const view = reactive({
      isAuth: false,

      search_years: [],

      idx: 0,
      page: 1,
      rows: 50,
      year: "",
      type: "detail",

      info: {},
      list: [],
      total: 0,
      vacation: {
        rookie: {},
        annual: {},
        outing: {},
        carryover: {},
        etc: {},
      },
      used: {},
      state: {},
      goList: () => {
        router.push({
          name: "myPIMS-VacationManagement",
        });
      },

      makeSearchYears: () => {
        let ey = view.info.joindate.substr(0, 4);
        let cd = new Date();
        let cy = cd.getFullYear();
        for (let i = cy; i >= ey; i--) {
          view.search_years.push(i);
        }
      },

      changeYear: () => {
        if (view.idx > 0) {
          view.page = 1;
          view.doSearch();
        }
      },

      viewTab: (id) => {
        router.push({
          name: "myPIMS-PersonalInfoList_tab" + id + "-idx",
          params: { idx: view.idx },
        });
      },

      viewVaTab: (id) => {
        view.page = 1;
        view.type = id;
        if (view.idx > 0) {
          view.doSearch();
        }
      },

      doSearch: () => {
        let params = {
          idx: view.idx,
          page: view.page,
          rows: view.rows,
          year: view.year,
          type: view.type,
        };

        axios
          .get("/rest/mypims/getVacationList", { params: params })
          .then((res) => {
            if (res.data.err == 0) {
              view.list = res.data.list;
              view.total = res.data.total;
            } else {
              if (res.data.err_msg) toast.error(res.data.err_msg);
            }
          });
      },

      doSearchVA: () => {
        let params = {
          idx: view.idx,
        };

        axios
          .get("/rest/mypims/getHQInfoVA", { params: params })
          .then((res) => {
            if (res.data.err == 0) {
              view.info = res.data;
              view.vacation = res.data.vacation;
              view.used = res.data.used;

              view.idx = res.data.idx;

              view.makeSearchYears();
              view.doSearch();
            } else {
              if (res.data.err_msg) toast.error(res.data.err_msg);
            }
          });
      },

      doDelete: (idx) => {
        if (
          confirm(
            "삭제하는경우 복구가 되지 않습니다. \n정말로 삭제하시겠습니까?"
          )
        ) {
          let params = {
            idx: idx,
          };
          axios
            .get("/rest/mypims/delVacation", { params: params })
            .then((res) => {
              if (res.data.err == 0) {
                alert("삭제에 성공하였습니다.");
                view.doSearch();
              } else {
                if (res.data.err_msg) toast.error(res.data.err_msg);
              }
            });
        }
      },
    });

    onMounted(() => {
      // Mounted
      if (store.state.isLead === false && store.state.pd_auth === false) {
        router.go(-1);
        Swal.fire({
          title: "휴가 관리",
          text: "접근 권한이 없습니다.",
        });
        return;
      } else if (store.state.pd_auth) {
        view.isAuth = true;
      }
      view.state = store.state;
      let td = new Date();
      view.year = td.getFullYear();

      view.idx = route.params.idx ? route.params.idx : "0";

      view.doSearchVA();
    });

    return { pop, view };
  },
};
</script>

<style lang="scss" scoped>
.sub-wrap .con-wrap .table-col.table-auto {
  table-layout: auto;
}
</style>
