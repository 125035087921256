<template>
    <div class="carrot-layer-bg">
        <div class="carrot-layer w-600px">
            <div>
                <p class="title">추가휴가 등록</p> 
                <span class="btn-close" @click="pop.onClose()"><i class="fas fa-times"></i></span>
            </div>
            <div class="mt-10 tbl-light">
                <table class="tbl-light-view">
                    <colgroup>
                        <col width="100">
                        <col width="*">
                    </colgroup>
                    <tbody>
                        <tr>
                            <th>*추가일수</th>
                            <td>
                              <input type="text" v-model="pop.additional" class="w-120px txt-center" maxlength="3">일
                              <select  v-model="pop.additional" class=" ml-10">
                                <option value="0.5">0.5</option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                              </select>
                            </td>
                        </tr>
                        <tr>
                            <th>*종류</th>
                            <td>
                                <label class="float-left h-30px pt-5"><input type="radio" v-model="pop.vtype" value="annual">연차</label>
                                <label class="float-left h-30px pt-5 ml-10"><input type="radio" v-model="pop.vtype" value="rookie">루키</label>
                                <label class="float-left h-30px pt-5 ml-10"><input type="radio" v-model="pop.vtype" value="outing">아웃팅</label>
                                <label class="float-left h-30px pt-5 ml-10"><input type="radio" v-model="pop.vtype" value="etc">대체휴가</label>
                                <select v-if="pop.vtype=='etc'" v-model="pop.vtype2" class="float-left ml-10">
                                    <option value="">선택</option>
                                    <option value="compassionate">경조휴가</option>
                                    <option value="maternity2">배우자출산</option>
                                    <option value="biztrip">출장</option>
                                    <option value="education">교육</option>
                                    <option value="workshop">워크샵</option>
                                    <option value="reservedmili">병역훈련</option>
                                    <option value="medicheck">백신휴가</option>
                                    <option value="prenatal_diagnosis">태아검진휴가</option>
                                    <option value="comptime">휴일근무</option>
                                    <option value="etc">기타</option>
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <th>기간</th>
                            <td>
                                <carrot-date-picker v-model="pop.sdate" class="w-120px dp-inblock"></carrot-date-picker>
                                ~
                                <carrot-date-picker v-model="pop.edate" class="w-120px dp-inblock ml-10"></carrot-date-picker>
                                <div class="txt-red">
                                    ※ 법정공휴일 또는 주말은 자동 제외됩니다.<br>
                                    ※ 불특정 공휴일(대체공휴일 등)은 자동 제외되지 않으니 유의하시기 바랍니다.
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th>*사유</th>
                            <td>
                                <textarea v-model="pop.memo" class="w-100per h-100px"></textarea>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="layer-btn mt-10 w-100per dp-table txt-center">
                <button class="btn-default dp-inline" @click="pop.doSubmit">등록<!--저장--></button>
            </div>
        </div>
    </div>
</template>

<script>
import { onMounted, reactive } from 'vue'
import { useToast } from 'vue-toastification';
import axios from '@/plugins/axios.js';
import Swal from 'sweetalert2';
import CarrotDatePicker from '@/components/common/CarrotDatePicker.vue';

export default {
    name: 'VacationAddPopup',
    components:{
        CarrotDatePicker
    },
    props: {
        idx: {
            type: [Number, String],
            default: 0,
        }
    },
    setup(props, {emit}) {
        const toast = useToast()

        const pop = reactive({
            idx : 0,
            additional : "",
            vtype  : "",
            vtype2 : "",
            sdate  : "",
            edate  : "",
            memo   : "",


            doSubmit: () => {
                let params = {
                    idx : pop.idx,
                    add : pop.additional,
                    vtype  : pop.vtype,
                    vtype2 : pop.vtype2,
                    sdate : pop.sdate,
                    edate : pop.edate,
                    memo   : pop.memo
                };

                console.log(params); 

                if( !params.add || Number(params.add) != params.add ){
                    Swal.fire("추가일수를 입력하세요.");
                    return;
                }

                if( !params.vtype || ( params.vtype=='etc' && !params.vtype2 ) ){
                    Swal.fire("휴가 종류를 선택하세요.");
                    return;
                }

                if( !params.memo ){
                    Swal.fire("사유를 입력하세요.");
                    return;
                }

                axios.post("/rest/mypims/addVacation", params).then((res) => {
                    if( res.data.err == 0 ){
                        pop.onClose();
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                })
            },

            onClose: () => {
                emit('close');
            }
        });

        onMounted(() => {
            // Mounted
            pop.idx    = props.idx?props.idx:"0";
        });

        return {pop};
    }
}
</script>
<style lang="scss" scoped>
</style>